var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ma-2"},[(_vm.loaded)?_c('v-flex',{attrs:{"md12":""}},[_c('v-card',{staticClass:"mx-2 px-2"},[_c('bar-chart',{staticClass:"chart",attrs:{"chartdata":_vm.datacollection,"options":_vm.options}})],1)],1):_vm._e(),_c('DataFilter',{staticClass:"mt-2",attrs:{"filterProps":_vm.filters,"items":_vm.dados,"paginate":false},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-divider',{attrs:{"vertical":""}}),_c('div',{staticClass:"ml-0 mr-0",staticStyle:{"width":"150px"}},[_c('v-select',{attrs:{"label":"Itens por página","items":[10, 20, 50, 'Todos'],"max-width":"50"},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('div',{staticClass:"ml-0 mr-0",staticStyle:{"width":"120px"}},[_c('v-checkbox',{attrs:{"label":"Disponivel"},model:{value:(_vm.available),callback:function ($$v) {_vm.available=$$v},expression:"available"}})],1),_c('div',{staticClass:"ml-0 mr-0",staticStyle:{"width":"100px"}},[_c('v-checkbox',{attrs:{"label":"Atuais"},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 mr-2",attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){return _vm.getDados()}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-solid fa-rotate-right")])],1)]}}])},[_c('span',[_vm._v("Atualizar")])])]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":items,"hide-default-footer":"","items-per-page":items.length},scopedSlots:_vm._u([{key:"header.disponivel",fn:function(ref){
var header = ref.header;
return [_c('span',[_vm._v(_vm._s(header.text))])]}},{key:"item.versao",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/versoes/detalhes/" + (item['idversao']) + "?versaodesc=" + (item['versao'])),"tag":"a"}},[_vm._v(_vm._s(item["versao"]))])]}},{key:"item.disponivel",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item['disponivel'] ? 'success' : 'error'}},[_vm._v(_vm._s(item["disponivel"] ? "fa-check" : "fa-close"))]),_vm._v(" "+_vm._s(item["disponivel"] == null ? "X" : item["disponivel"])+" - "+_vm._s(item["disponivel"] ? "Disponível" : "Indisponível"))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{staticClass:"ma-0 pa-0",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v("fa-comment")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item["motivo"]))])]),_c('v-btn',{attrs:{"icon":"","color":"success","disabled":item['disponivel'] == null || (!_vm.isAdmin() && item['disponivel'] != null)},on:{"click":function($event){return _vm.selectVersao(item)}}},[_c('v-icon',[_vm._v("fa-edit")])],1)]}}],null,true)})]}}])})],1),_c('v-dialog',{attrs:{"width":"800px","persistent":"","transition":"dialog-transition"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[(_vm.versaoSelecionada && _vm.showDialog)?_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.versaoSelecionada.versao))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.versaoSelecionada.motivo ? "Ultimo motivo: " + _vm.versaoSelecionada.motivo : ""))]),_c('v-layout',{staticClass:"mx-3",attrs:{"row":"","align-center":"","wrap":""}},[_c('v-flex',{attrs:{"md2":""}},[_c('v-combobox',{attrs:{"solo":"","dense":"","label":"Versão disponível","item-text":"text","item-value":"value","items":_vm.stateItems,"value":_vm.versaoSelecionada.disponivel ? 'Disponível' : 'Indisponível'},on:{"change":_vm.setDisponivel}})],1),_c('v-flex',{staticClass:"ml-2"},[_c('v-text-field',{attrs:{"dense":"","label":"Motivo"},model:{value:(_vm.motivo),callback:function ($$v) {_vm.motivo=$$v},expression:"motivo"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.resetMotivoVersao}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-close")]),_c('span',[_vm._v("Cancelar")])],1),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.alterarDisponibilidade}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-check")]),_c('span',[_vm._v("Confirmar")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }